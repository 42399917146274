import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useRequestData, usePrefs } from 'ws-scripts/modules/common';
import { navLinkClicked } from '../utils/tracking';

const NavLink = ({
	additionalAttrs,
	children,
	classes,
	target,
	topLevelNavIndex,
	url
}) => {
	const { widgetName, windowId } = useRequestData();
	const { enableMegaMenus, 'navigation.id': navId } = usePrefs();
	const linkAttrs = { ...additionalAttrs };
	const belongsToFooter =
		navId.toLowerCase().includes('footer') ||
		windowId === 'template-navigation2';
	const belongsToMainHeader = windowId === 'template-navigation1';

	if (url) linkAttrs.href = url.replaceAll(/\s/g, '');
	if (classes) linkAttrs.className = classes;
	if (target) linkAttrs.target = target;

	linkAttrs['data-navigation-id'] = 'default';
	const openMenus = useSelector((state) => state.menu.openMenus);
	const isOpen = openMenus?.includes(topLevelNavIndex);

	const handleTrackingOnClick = (e) => {
		const linkText = e.target.textContent || e.target.ariaLabel;
		const isMegaMenuOpening =
			e.target.hasAttribute('data-toggle') && enableMegaMenus && !isOpen;
		navLinkClicked(
			widgetName,
			windowId,
			belongsToFooter,
			belongsToMainHeader,
			linkText,
			e.target.href,
			isMegaMenuOpening
		);
	};

	if (linkAttrs.onClick) {
		const handlerFromProps = linkAttrs.onClick;
		linkAttrs.onClick = (e) => {
			handlerFromProps(e);
			handleTrackingOnClick(e);
		};
	} else {
		linkAttrs.onClick = handleTrackingOnClick;
	}

	return <a {...linkAttrs}>{children}</a>;
};

NavLink.propTypes = {
	additionalAttrs: PropTypes.oneOfType([PropTypes.object]),
	children: PropTypes.string.isRequired,
	classes: PropTypes.string,
	id: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
	target: PropTypes.string.isRequired,
	topLevelNavIndex: PropTypes.number,
	url: PropTypes.string.isRequired
};

export default NavLink;
