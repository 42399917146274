import React from 'react';
import PropTypes from 'prop-types';
import MMColumnSection from './MMColumnSection';
import NavLink from './NavLink';

const MMColumn = ({ navigationItems, gridUnits, label, topMenuItemIndex }) => {
	return (
		<li key={label} className={`ddc-span${gridUnits}`}>
			{navigationItems?.map((parent, i) => {
				const key = `${parent.label}-${topMenuItemIndex}-${i}`;
				if (parent.navigationItems.length === 0) {
					return (
						<h4 className="parent" key={key}>
							<NavLink
								additionalAttrs={{
									'data-navigation-id': 'default'
								}}
								target={parent.target}
								url={parent.url}
							>
								{parent.label}
							</NavLink>
						</h4>
					);
				} else {
					return (
						<MMColumnSection
							itemsData={parent.navigationItems}
							headerLabel={parent.label}
							topMenuItemIndex={topMenuItemIndex}
							key={key}
						/>
					);
				}
			})}
		</li>
	);
};

MMColumn.propTypes = {
	navigationItems: PropTypes.arrayOf.isRequired,
	gridUnits: PropTypes.number.isRequired,
	label: PropTypes.string.isRequired,
	topMenuItemIndex: PropTypes.number.isRequired
};

export default MMColumn;
