import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import NavLink from './NavLink';

// Works for DropdownItem and MegaMenu components
const MenuLink = ({
	childListLinkClasses = '',
	children, // <NavIcon/>
	linkAttrs = {},
	outerAttrs = {},
	outerClasses = '',
	setIsNavLink = () => {},
	target = '',
	url = ''
}) => {
	useEffect(() => {
		setIsNavLink(true);
	});

	const currentPath = useSelector((state) => state.currentPage?.currentPath);
	const isCurrentPage = Boolean(url === currentPath);
	const listItemAttrs = { ...outerAttrs };
	if (isCurrentPage) {
		listItemAttrs['aria-current'] = 'page';
	}
	listItemAttrs.className = isCurrentPage
		? `${outerClasses} currentPage`
		: outerClasses;

	return (
		<li {...listItemAttrs}>
			<NavLink
				url={url}
				classes={childListLinkClasses}
				target={target}
				additionalAttrs={linkAttrs}
			>
				{children}
			</NavLink>
		</li>
	);
};

MenuLink.propTypes = {
	childListLinkClasses: PropTypes.string.isRequired,
	children: PropTypes.string.isRequired,
	id: PropTypes.string.isRequired,
	isCurrentPage: PropTypes.bool.isRequired,
	label: PropTypes.string.isRequired,
	linkAttrs: PropTypes.oneOfType([PropTypes.object]),
	outerAttrs: PropTypes.oneOfType([PropTypes.object]),
	outerClasses: PropTypes.string.isRequired,
	selected: PropTypes.bool,
	setIsNavLink: PropTypes.func,
	target: PropTypes.string.isRequired,
	url: PropTypes.string.isRequired
};

export default MenuLink;
