import React from 'react';
import PropTypes from 'prop-types';
import { usePrefs } from 'wsm-common-data';
import MenuLink from './MenuLink';
import NavIcon from './NavIcon';

const DropdownItem = ({ url, target, label, enabled }) => {
	const { childListLinkClasses } = usePrefs();

	if (!enabled) return <></>;

	const classes = `child ${childListLinkClasses}`;
	const additionalAttrs = { 'data-navigation-id': 'default' };

	return (
		<MenuLink
			childListLinkClasses={classes}
			url={url}
			target={target}
			additionalAttrs={additionalAttrs}
		>
			<NavIcon label={label} />
		</MenuLink>
	);
};
DropdownItem.propTypes = {
	url: PropTypes.string.isRequired,
	target: PropTypes.string.isRequired,
	enabled: PropTypes.bool.isRequired,
	label: PropTypes.string.isRequired
};

export default DropdownItem;
