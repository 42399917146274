import { trackEvent } from 'ddc-track-event';

export const TRACKING_CONFIG = {
	mmOpenedResult: 'Mega Menu Opened',
	getNavigatedResult: (url) => `Navigated to ${url}`,
	getFooterEl: (itemText) => `Sitewide Footer Click - ${itemText}`,
	getHeaderEl: (itemText) => `Sitewide Header Click - ${itemText}`
};

/**
 * Create the navigation link clicked tracking event based on where it took place (header or footer)
 * and whether it resulted in a page navigation or opening a Mega Menu
 * @param {*} widgetName
 * @param {*} windowId
 * @param {*} belongsToFooter
 * @param {*} belongsToMainHeader
 * @param {*} linkText
 * @param {*} url
 * @param {*} isOpeningMegaMenu
 */
export const navLinkClicked = (
	widgetName,
	windowId,
	belongsToFooter,
	belongsToMainHeader,
	linkText,
	url = '',
	isOpeningMegaMenu
) => {
	if (!(belongsToFooter || belongsToMainHeader)) {
		return;
	}

	const element = belongsToFooter
		? TRACKING_CONFIG.getFooterEl(linkText)
		: TRACKING_CONFIG.getHeaderEl(linkText);

	let result = TRACKING_CONFIG.getNavigatedResult(url);
	if (isOpeningMegaMenu) {
		result = TRACKING_CONFIG.mmOpenedResult;
	}

	trackEvent(widgetName, windowId, {
		action: 'clicked',
		element,
		result
	});
};
