import '../global-sass/main.scss';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { usePrefs, useRequestData } from 'wsm-common-data';
import { isBrowser } from 'ws-scripts/modules/environment';
import Parser from 'html-react-parser';
import MenuItem from '../components/MenuItem';
import { getNavClasses } from '../utils/getNavClasses';
import { getNavAttributes } from '../utils/getNavAttributes';
import { MENU_TYPE_MEGA_MENU } from '../constants';
import { setUri } from '../actions/currentPage';

const Widget = () => {
	const navItems = useSelector((state) => state.items);
	const navsV2 = useSelector((state) => state.widgetData.navsV2);
	const {
		dropdownDisplayType,
		enableMegaMenus,
		enableSiteWideSearch,
		enableTwoLineLabelling,
		navClasses,
		navListClasses,
		widgetClasses,
		showFooterLinksIcons,
		showCurtain,
		sticky,
		'navigation.id': navId
	} = usePrefs();

	if (typeof window !== 'undefined') {
		window.DDC = window.DDC || {};
		window.DDC.EventFired = window.DDC.EventFired || {};
	}

	const dispatch = useDispatch();
	const siteWidePositionFirst = enableSiteWideSearch === 'first';
	const siteWidePositionLast = enableSiteWideSearch === 'last';
	const { composer } = useRequestData();
	const [isSiteTextSearch, setIsSiteTextSearch] = useState(false);

	useEffect(() => {
		if (typeof window !== 'undefined') {
			const { pathname } = window?.location;
			dispatch(setUri(pathname));
		}
	}, [dispatch]);

	useEffect(() => {
		let count = 0;
		const checkForWsSiteTextSearch = () => {
			count += 1;
			if (window?.DDC?.EventFired['ws-sts-navigation-registered']) {
				setIsSiteTextSearch(true);
			} else if (count < 15) {
				setTimeout(checkForWsSiteTextSearch, 100);
			}
		};
		checkForWsSiteTextSearch();
	}, []);

	// matchHeight() must fire once, as early as possible
	useEffect(() => {
		function matchHeight(init) {
			$('.dropdown.dropdown').each(function matchingHeightCallback() {
				const $menuPanel = $(this);

				if ($menuPanel.hasClass('open') || init) {
					const $item = $menuPanel.find('.dropdown-menu');
					$item.css({ maxHeight: '70vh', overflowY: 'auto' });
				}
			});
		}

		if (
			enableMegaMenus === 'true' &&
			isBrowser &&
			typeof window.jQuery === 'function'
		) {
			matchHeight(true);
		}
	});

	// helper-function that returns all classes and attributes needed for megamenu and non-mega-menu
	// TODO: need a way to access the WIAPI data location and add that as an attribute$ !wiapi.dataLocation($menuHref).toAttributeStr()
	const getNavClassesAndAttr = () => {
		const menuType =
			enableMegaMenus === 'true' ? MENU_TYPE_MEGA_MENU : 'default';

		const classesToReturn = getNavClasses(menuType, {
			widgetClasses,
			sticky,
			enableTwoLineLabelling,
			navListClasses,
			navsV2,
			navItems
		});

		const attributesToReturn = getNavAttributes(menuType, {
			dropdownDisplayType,
			composer,
			navId,
			showCurtain
		});

		return [classesToReturn.join(' '), attributesToReturn];
	};

	// universal attributes and classes for the nav (react-bootstrap turns this into a <ul>)
	const [classes, attributes] = getNavClassesAndAttr();

	// render outer-nav component if the nav is a non-mega-menu
	const MenuWrapper = (Component) => {
		if (enableMegaMenus === 'true') return Component;

		const nonMMWrapperAttributes = {};
		const nonMMWrapperClasses = `non-mega-menu ${
			navClasses.toLowerCase() === 'blank' ? '' : `${navClasses}`
		}`;

		if (composer) {
			nonMMWrapperAttributes.navid = navId;
		}

		if (showFooterLinksIcons === 'true')
			nonMMWrapperAttributes['data-show-footer-links-icons'] = '';

		return (
			<nav className={nonMMWrapperClasses} {...nonMMWrapperAttributes}>
				{Component}
			</nav>
		);
	};

	return MenuWrapper(
		<ul className={classes} {...attributes}>
			{siteWidePositionFirst && isSiteTextSearch && (
				<MenuItem
					isSiteWideSearchNavItem={isSiteTextSearch}
					siteWidePositionFirst
					siteWidePositionLast={false}
					searchPosition="first"
				/>
			)}
			{navItems.length > 0 &&
				navItems
					.filter((navItem) => navItem?.state !== 'disabled')
					.map((item, index) => {
						const {
							columns,
							id,
							label,
							layout,
							navigationItems,
							pageId,
							target,
							url
						} = item;
						const labelValue = Parser(label);
						const menuItemKey = `${id}-${index}`;
						return (
							<MenuItem
								columns={columns}
								id={id}
								index={index}
								isSiteWideSearchNavItem={false}
								key={menuItemKey}
								label={labelValue}
								layout={layout}
								numTopLevelItems={navItems.length}
								pageId={pageId}
								siteWidePositionFirst={siteWidePositionFirst}
								siteWidePositionLast={siteWidePositionLast}
								subnavItems={navigationItems}
								target={target}
								url={url}
							/>
						);
					})}
			{siteWidePositionLast && isSiteTextSearch && (
				<MenuItem
					isSiteWideSearchNavItem={isSiteTextSearch}
					siteWidePositionFirst={false}
					siteWidePositionLast
					searchPosition="last"
				/>
			)}
		</ul>
	);
};

export default Widget;
