import { MENU_TYPE_MEGA_MENU } from '../constants';

/**
 * @typedef { Object } NavAttrMetadataInput
 * @property { string? } dropdownDisplayType
 * @property { boolean? } composer
 *
 * @typedef { Object } NavAttributesResult
 * @property { string? } data-dropdown-display-type
 * @property { boolean? } composer
 * @property { string? } navId
 * @property { string? } showCurtain
 */

/**
 * Gets the nav attributes for a mega menu navigation
 *
 * @param { NavAttrMetadataInput } metadata
 * @returns { NavAttributesResult }
 */
export const getMegaMenuAttrs = (metadata) => {
	const {
		dropdownDisplayType = '',
		composer = false,
		navId,
		showCurtain = 'false'
	} = metadata;

	const megaMenuAttrs = {};
	if (dropdownDisplayType !== '') {
		megaMenuAttrs['data-dropdown-display-type'] = dropdownDisplayType;
		megaMenuAttrs.role = 'list';
	}
	if (composer) megaMenuAttrs.navid = navId;
	if (showCurtain === 'false') megaMenuAttrs['disable-curtain'] = '';
	return megaMenuAttrs;
};

/**
 * gets the default attributes for a navigation
 *
 * @returns { NavAttributesResult }
 */
export const getDefaultAttrs = () => {
	const navAttributes = {
		role: 'list'
	};
	return navAttributes;
};

/**
 * builds the attributes for a navigation based on menuType
 *
 * @param { string } menuType
 * @param { NavAttrMetadataInput } metadata
 * @returns { NavAttributesResult }
 */
export const getNavAttributes = (menuType, metadata) => {
	switch (menuType) {
		case MENU_TYPE_MEGA_MENU:
			return getMegaMenuAttrs(metadata);
		default:
			return getDefaultAttrs();
	}
};
