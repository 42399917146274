/**
 *
 * @desc Compares current path with the url of each navigation item to set 'currentPageParent' styles in <NavItemLink/>
 * @param {Array} items
 * @returns {Boolean}
 *
 */

export const setSelected = (items = [], currentPath) => {
	if (!items.length || !currentPath) {
		return false;
	}

	let found = false;
	items.forEach((parentItem) => {
		if (parentItem.url === currentPath) {
			found = true;
		} else {
			parentItem.navigationItems.forEach((childItem) => {
				if (childItem.url === currentPath) {
					found = true;
				}
			});
		}
	});
	return found;
};
