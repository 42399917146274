/**
 *
 * @desc dispatches new event for consumption by ws-tracking. e.stopPropagation() prevents this but is required to preserve click functionality
 * @param {e} event
 * @returns {Event}
 *
 */

export const dispatchTrackingEvent = (e) => {
	const event = new CustomEvent('ws-tracking-nav-event', {
		detail: { event: e }
	});
	document.dispatchEvent(event);
};
