import {
	SET_NAVSV2,
	SET_FRANCHISELIST,
	SET_ACCOUNT_ID
} from '../actions/types';

export default function items(
	state = { navsV2: true, franchiseList: [], accountId: '' },
	action
) {
	if (!action.error && action.payload !== undefined) {
		switch (action.type) {
			case SET_NAVSV2:
				return { ...state, navsV2: action.payload };
			case SET_FRANCHISELIST:
				return { ...state, franchiseList: action.payload };
			case SET_ACCOUNT_ID:
				return { ...state, accountId: action.payload };
			// no default
		}
	}

	return state;
}
