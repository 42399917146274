import { OPEN_MENU, CLOSE_MENU, CLOSE_ALL_MENUS } from './types';

export const openMenu = (menuItemId) => ({
	type: OPEN_MENU,
	menuItemId
});

export const closeMenu = (menuItemId) => ({
	type: CLOSE_MENU,
	menuItemId
});

export const closeAllMenus = () => ({
	type: CLOSE_ALL_MENUS
});
