import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { usePrefs } from 'wsm-common-data';
import { isBrowser } from 'ws-scripts/modules/environment';
import { closeMenu, closeAllMenus } from '../actions/menu';
import MMColumn from './MMColumn';
import { dispatchTrackingEvent } from '../utils/dispatchTrackingEvent';

const MegaMenu = ({ index, columns }) => {
	const dispatch = useDispatch();
	const { dropdownDisplayType } = usePrefs();

	const openMenus = useSelector((state) => state.menu.openMenus);
	const itemKey = useSelector((state) => state.items[index].id);
	const isOpen = openMenus?.includes(index);
	const mMDropDownRef = useRef(null);

	// Click event listener to handle outside clicks
	useEffect(() => {
		const closeMenuAction = () => {
			dispatch(closeAllMenus());
			$('.navbar-nav li.dropdown')
				.eq(index)
				.trigger('hidden.bs.dropdown');
		};

		const handleOutsideClick = (event) => {
			const eTargetIsMenuItem = Array.from(
				event.target.attributes
			).filter(
				(obj) =>
					obj.nodeValue.includes('nav-with-children') ||
					obj.nodeValue.includes('dropdown')
			);
			if (isOpen && !eTargetIsMenuItem.length) {
				closeMenuAction();
			}
		};

		document.addEventListener('click', handleOutsideClick);
		return () => {
			document.removeEventListener('click', handleOutsideClick);
		};
	}, [dispatch, index, isOpen]);

	// As a note - This function was added to prevent closing the MegaMenu when clicking it,
	// especifically when filling out a form within it. We were unable to locate where
	// this behavior is defined in the legacy widget/cms-web.This may cause issues for us in the future.
	const handleClickWithinMenu = (e) => {
		const parentWidget = e.target.closest('[data-widget-name]');
		const parentWidgetName = parentWidget?.dataset?.widgetName;

		const typeAttr = e.target?.attributes?.type?.value;

		if (
			(typeAttr !== 'submit' &&
				parentWidgetName !== 'ws-free-text-search') ||
			parentWidgetName.includes('contact-form')
		) {
			dispatchTrackingEvent(e);
			e.stopPropagation();
		}
	};

	const dropdownProps = {
		onClick: handleClickWithinMenu
	};

	if (dropdownDisplayType === 'hover') {
		dropdownProps.onMouseLeave = (e) => {
			if (e.target !== e.currentTarget) {
				return;
			}

			dispatch(closeMenu(index));
			$('.navbar-nav li.dropdown').trigger('hidden.bs.dropdown');
		};
	}

	const showBackground = () => {
		if (isBrowser && typeof window.jQuery === 'function') {
			if (isOpen && mMDropDownRef.current) {
				// We do this to prevent issues when there's more than one ws-navigation on the page
				const parentWidget = mMDropDownRef.current.closest(
					'[data-widget-name="ws-navigation"]'
				);

				$(parentWidget)
					.find('.navbar-nav li.dropdown')
					.eq(index)
					.trigger('show.bs.dropdown');
			}
		}
	};

	showBackground();

	return (
		<ul
			key={itemKey}
			className="dropdown-menu row"
			{...dropdownProps}
			ref={mMDropDownRef}
		>
			{columns?.map((item, i) => {
				const columnKey = `${itemKey}-${i}`;
				return (
					<MMColumn
						navigationItems={item?.navigationItems}
						gridUnits={item?.gridUnits}
						label={item?.navigationItems[i]?.label}
						topMenuItemIndex={index}
						key={columnKey}
					/>
				);
			})}
		</ul>
	);
};

MegaMenu.propTypes = {
	index: PropTypes.string.isRequired,
	columns: PropTypes.arrayOf.isRequired
};

export default MegaMenu;
