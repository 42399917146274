import { MENU_TYPE_MEGA_MENU } from '../constants';

/**
 * @typedef { Object } NavClassesMetadataInput
 * @property { string? } widgetClasses
 * @property { string? } sticky
 * @property { string? } enableTwoLineLabelling
 * @property { any[]? } navItems
 * @property { boolean? } navsV2
 */

/**
 * builds the className for how many items are visible in the navbar
 *
 * @param { number } navItemCount
 * @returns { string }
 */
export const getParentNavCount = (navItemsCount) => {
	return `parent-nav-count-${navItemsCount} navbar-nav`;
};

/**
 * gets the classList for a mega menu navigation
 *
 * @param { NavClassesMetadataInput? } metadata
 * @returns { string[] }
 */
export const getMegaMenuClasses = (metadata = {}) => {
	const {
		enableTwoLineLabelling,
		navListClasses = 'BLANK',
		sticky,
		widgetClasses = 'BLANK'
	} = metadata;

	const navClasses = [
		// conditionally add `navListClasses` if not blank
		...(navListClasses.toLowerCase() === 'blank' ? '' : [navListClasses]),
		// conditionally add `widgetClasses` if it is not blank
		...(widgetClasses.toLowerCase() === 'blank' ? [] : [widgetClasses]),
		'ddc-mega-menu-nav',
		'nav',
		'd-flex',
		'align-items-center'
	];
	if (sticky === 'true') navClasses.push('sticky-nav');
	if (enableTwoLineLabelling === 'true')
		navClasses.push('enable-sublabelling');

	return navClasses;
};

/**
 * * gets the default classList for a navigation
 *
 * @param { NavClassesMetadataInput? } metadata
 * @returns { string[] }
 */
export const getDefaultClasses = (metadata = {}) => {
	const {
		navItems = [],
		navListClasses = 'BLANK',
		navsV2 = false,
		sticky
	} = metadata;

	const navClasses = [
		'nav-list',
		// conditionally add `navListClasses` if not blank
		...(navListClasses.toLowerCase() === 'blank' ? '' : [navListClasses])
		// `widgetClasses` are not added here as they are only used in MENU_TYPE_MEGA_MENU
		// Adding these free form classes produces unexpected inheritance issues and breakages in non mega menu
	];
	if (sticky === 'true') navClasses.push('sticky-nav');
	if (navsV2) navClasses.push('nav');
	if (navItems.length) navClasses.push(getParentNavCount(navItems.length));
	return navClasses;
};

/**
 * builds the classList for a navigation based on menuType
 *
 * @param { string } menuType
 * @param { NavClassesMetadataInput } metadata
 * @returns { string[] }
 */
export const getNavClasses = (menuType, metadata) => {
	switch (menuType) {
		case MENU_TYPE_MEGA_MENU:
			return getMegaMenuClasses(metadata);
		default:
			return getDefaultClasses(metadata);
	}
};
