import { SET_URI } from '../actions/types';

export default function currentPage(state = { currentPath: '' }, action) {
	if (!action.error && action.payload !== undefined) {
		switch (action.type) {
			case SET_URI:
				return { ...state, currentPath: action.payload };
			// no default
		}
	}

	return state;
}
