import React from 'react';
import PropTypes from 'prop-types';

const MMColumnItem = ({ headerLabel, children }) => {
	return (
		<>
			<h4>{headerLabel}</h4>
			{children}
		</>
	);
};

MMColumnItem.propTypes = {
	headerLabel: PropTypes.string.isRequired,
	children: PropTypes.node.isRequired
};

export default MMColumnItem;
