import items from './items';
import widgetData from './widgetData';
import menu from './menu';
import currentPage from './currentPage';

const root = {
	items,
	widgetData,
	menu,
	currentPage
};

export default root;
