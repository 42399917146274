import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

const NavFragment = ({
	url,
	topMenuItemIndex,
	setIsNavLink = () => {},
	fragmentId,
	fragmentUrl
}) => {
	const openMenus = useSelector((state) => state.menu.openMenus);
	const isTopMenuItemOpen = openMenus?.includes(topMenuItemIndex);
	const navFragmentRef = useRef(null);
	setIsNavLink(false);

	const classes = {
		ajaxLoadingContainer: 'navigation-ajax-container',
		ajaxLoadComplete: 'ajax-load-complete',
		ddcIcon: 'ddc-icon',
		ddcIconSize: 'ddc-icon-size-xlarge',
		ddcIconGear: 'ddc-icon-specs-gear1',
		hide: 'hide',
		loading: 'loading',
		open: 'open'
	};

	const selectors = {
		ddcContent: '.ddc-content',
		ddcMegaMenuNav: '.ddc-mega-menu-nav.nav',
		ajaxLoadComplete: '.ajax-load-complete',
		loading: '.loading'
	};

	const events = { ajaxComplete: 'ddc-mega-menu-nav-ajax-load-complete' };

	// prevents unnecessary rendering of fragments
	if (
		isTopMenuItemOpen &&
		typeof window.jQuery === 'function' &&
		!navFragmentRef.current.innerHTML
	) {
		const ajaxTime = new Date().getTime();
		$(document).trigger('modulesRefresh', {
			modules: { lazyImage: window.DDC.modules.lazyImage }
		});

		$(navFragmentRef.current).not(selectors.ajaxLoadComplete).before(`
					<span class="${classes.ajaxLoadingContainer} ${classes.loading}">
						<i class="${classes.ddcIcon} ${classes.ddcIconGear} ${classes.ddcIconSize}"></i> Loading...
					</span>`);

		$(navFragmentRef.current)
			.html('')
			.removeClass(classes.hide)
			.load(url, function loadCallback() {
				const thisLoad = $(this);
				const totalTime = new Date().getTime() - ajaxTime;
				let delayTime = null;
				delayTime = totalTime > delayTime ? 0 : delayTime - totalTime;

				thisLoad.delay(delayTime).queue(function delayCallback() {
					$(this)
						.css('visibility', 'visible')
						.siblings(selectors.loading)
						.remove();
					$(document).trigger('modulesRefresh');
				});
				thisLoad
					.find(selectors.ddcContent)
					.first()
					.trigger(events.ajaxComplete);
			})
			.addClass(classes.ajaxLoadComplete);
	}

	return (
		<li role="presentation">
			<div
				className="hide ajax-navigation-element"
				data-fragment-id={fragmentId}
				data-fragment-url={fragmentUrl}
				ref={navFragmentRef}
			/>
		</li>
	);
};

export default NavFragment;

NavFragment.propTypes = {
	url: PropTypes.string.isRequired,
	topMenuItemIndex: PropTypes.number.isRequired,
	setIsNavLink: PropTypes.func,
	fragmentId: PropTypes.string.isRequired,
	fragmentUrl: PropTypes.string.isRequired
};
